<template>
  <van-skeleton v-if="loading" :row="6" />
  <div v-else class="page">
    <router-link :to="{name:'order_detail', query: { id:item.id }}" class="one"  v-for="( item, index) in list" :key="index" >
      <div class="left">
        <div class="title">{{item.title}}</div>
        <div class="desc">{{item.real_pay}}元</div>
        <div class="time">{{item.create_time}}</div>
      </div>

      <div class="right">
        <div><span class="middle">{{item.state_mark}}</span></div>
      </div>
    </router-link>
    <p v-if="!list.length && !loading2" class="tips">您还没有订单呢</p>
    <p v-else-if="page===-1  && !loading2" class="tips">-- End --</p>
    <button v-if="page !==-1 && !loading2" class="in_body black" @click="get_lists">查看更多</button>

    <div class="foot" >
      <button class="black w1" data-value="order_find" @click="xml_go_to_only">订单不见了？找回订单</button>
    </div>
    <ul id="last_right_b" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>
    <van-popup v-model:show="show.contact" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Contact></Contact>
    </van-popup>
  </div>
</template>

<script>
import axios from 'axios'
import Utils from '@/utils/utils'
import Contact from '@/components/other/Contact'

export default {
  name: 'orders',
  components: {
    Contact
  },
  data () {
    return {
      loading: true,
      loading2: false,
      show: { contact: false },
      list: [],
      page: 1
    }
  },
  created () {
    this.get_lists()
  },
  methods: {

    get_lists () {
      if (this.loading2 || this.page === -1) {
        return
      }
      axios.post('/get_order_list/', { tag: 'all', page: this.page })
        .then(res => {
          this.loading = false
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          const ResList = res.data.data.list
          for (const i in ResList) {
            ResList[i].create_time = Utils.format_time_easy(ResList[i].create_time)
          }
          if (ResList.length === 20) {
            this.list = this.list.concat(ResList)
            this.page = this.page + 1
          } else if (ResList.length !== 0) {
            this.list = this.list.concat(ResList)
            this.page = -1
          } else {
            this.page = -1
          }
        })
    },
    xml_go_to_only (e) {
      console.log(e)
      Utils.go_to(e)
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    on_close () {
      this.show = { contact: false }
    }
  }
}
</script>

<style>
.one {
  margin: 0.4rem 0rem;
  padding: 0.4rem 0.5rem;
  background-color:#FEFFFE;
  display: block;

}

.one .left,.one .right{
  display: inline-block;
  vertical-align: middle;

}
.one .left div,.one .right div{
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.one .left{
  width: 6rem;
  border-right: 0.02rem dashed #ccc;
  padding-right: 0.5rem;
  text-align: left;
}

.one .right{
  width: 2rem;
  text-align:center;
}

.one .right .large{
  font-size: 0.72rem;
}

.one .right .small{
  font-size: 0.3rem;
}

.one .left .title{
  font-weight: bold;
}

.one .left .desc,.one .left .time{
  color: #555;
}

</style>
